// React.lazy doesn't work with HMR
import loadable from "@loadable/component";

export const iconSvgMap = {
  arrowDown: loadable(() => import("./icons/ArrowDown")),
  arrowLeft: loadable(() => import("./icons/ArrowLeft")),
  arrowRight: loadable(() => import("./icons/ArrowRight")),
  arrowRightBold: loadable(() => import("./icons/ArrowRightBold")),
  arrowRightCircle: loadable(() => import("./icons/ArrowRightCircle")),
  arrowThinRight: loadable(() => import("./icons/ArrowThinRight")),
  arrowThinDown: loadable(() => import("./icons/ArrowThinDown")),
  arrowUp: loadable(() => import("./icons/ArrowUp")),
  arrowSwoosh: loadable(() => import("./icons/ArrowSwoosh")),
  catProfile: loadable(() => import("./icons/CatProfile")),
  catWalking: loadable(() => import("./icons/CatWalking")),
  catWeightChubby: loadable(() => import("./icons/CatWeightChubby")),
  catWeightJustRight: loadable(() => import("./icons/CatWeightJustRight")),
  catWeightObese: loadable(() => import("./icons/CatWeightObese")),
  catWeightSkinny: loadable(() => import("./icons/CatWeightSkinny")),
  chevronLeft: loadable(() => import("./icons/ChevronLeft")),
  chevronRight: loadable(() => import("./icons/ChevronRight")),
  chicken: loadable(() => import("./icons/Chicken")),
  circle: loadable(() => import("./icons/Circle")),
  cow: loadable(() => import("./icons/Cow")),
  cross: loadable(() => import("./icons/Cross")),
  crossHeavy: loadable(() => import("./icons/CrossHeavy")),
  duck: loadable(() => import("./icons/Duck")),
  equals: loadable(() => import("./icons/Equals")),
  error: loadable(() => import("./icons/Error")),
  facebook: loadable(() => import("./icons/Facebook")),
  fish: loadable(() => import("./icons/Fish")),
  instagram: loadable(() => import("./icons/Instagram")),
  information: loadable(() => import("./icons/Information")),
  lightBulb: loadable(() => import("./icons/LightBulb")),
  linkedin: loadable(() => import("./icons/LinkedIn")),
  magnifyingGlass: loadable(() => import("./icons/MagnifyingGlass")),
  menu: loadable(() => import("./icons/Menu")),
  minus: loadable(() => import("./icons/Minus")),
  minusAlt: loadable(() => import("./icons/MinusAlt")),
  padlock: loadable(() => import("./icons/Padlock")),
  pause: loadable(() => import("./icons/Pause")),
  pig: loadable(() => import("./icons/Pig")),
  play: loadable(() => import("./icons/Play")),
  plus: loadable(() => import("./icons/Plus")),
  plusAlt: loadable(() => import("./icons/PlusAlt")),
  plusHeavy: loadable(() => import("./icons/PlusHeavy")),
  sheep: loadable(() => import("./icons/Sheep")),
  star: loadable(() => import("./icons/Star")),
  success: loadable(() => import("./icons/Success")),
  tag: loadable(() => import("./icons/Tag")),
  thumbsUp: loadable(() => import("./icons/ThumbsUp")),
  thumbsUpFilled: loadable(() => import("./icons/ThumbsUpFilled")),
  thumbsDown: loadable(() => import("./icons/ThumbsDown")),
  thumbsDownFilled: loadable(() => import("./icons/ThumbsDownFilled")),
  tick: loadable(() => import("./icons/Tick")),
  tickInverted: loadable(() => import("./icons/TickInverted")),
  tickAlt: loadable(() => import("./icons/TickAlt")),
  tickHeavy: loadable(() => import("./icons/TickHeavy")),
  tray: loadable(() => import("./icons/Tray")),
  turkey: loadable(() => import("./icons/Turkey")),
  warning: loadable(() => import("./icons/Warning")),
  youtube: loadable(() => import("./icons/Youtube")),
};

export const iconSizeClassMap = {
  "2xsmall": "w-4 h-4",
  xsmall: "w-6 h-6",
  small: "w-8 h-8",
  default: "w-11 h-11", // Minimum clickable for accessibility purposes (44px)
  medium: "w-12 h-12",
  large: "w-24 h-24",
  xlarge: "w-48 h-48",
  fit: "w-full h-full",
  radio: "w-16 h-16 lg:w-24 lg:h-24",
};

export type Size = keyof typeof iconSizeClassMap;
export type IconSvg = keyof typeof iconSvgMap;
